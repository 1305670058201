/* header css start */
.top_header {
  background-color: #125875;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  display: none;
}

.top_header .top-btn {
  text-align: center;
}

.top-btn a {
  background: #fe9d00;
  margin: 0px 6px 0px;
  padding: 7px 10px;
  color: #fff;
}

.header-top {
  border: none;
  padding-bottom: 15px;
  background: #141b22;
  padding-top: 15px;
  color: #fff;
  position: relative;
  z-index: 2;
  background: #00001a;
}

.header-top::before {
  content: "";
  height: 100%;
  position: absolute;
  width: 32%;
  background: #fe9d00;
  top: 0;
  z-index: -1;
  left: -25px;
  transform: skew(30deg);
}

.header-top span {
  color: #fff;
}

.header-top a {
  color: #fff;
}

.header-social a {
  margin-left: 20px;
  display: inline-block;
  text-align: center;
  font-size: 18px;
}

a.site-menu-toggle {
  width: 100%;
  height: 100%;
  display: block;
  padding: 4px;
  margin-top: -5px;
}

.header-cta ul li:first-child {
  margin-left: 0;
}

.header-cta ul li:last-child {
  padding-right: 0;
  border-right: none;
  margin-right: 0;
}

.header-cta .call-box {
  display: flex;
  text-align: left;
}

.call-box .icon {
  display: inline-block;
}

.call-box .text {
  margin-left: 10px;
}

.header-cta .call-box .icon img {
  width: 35px;
}

.call-box span {
  display: block;
  font-size: 12px;
  color: #fff;
  margin-bottom: -5px;
}

.call-box strong {
  font-size: 20px;
}

.call-box strong a {
  text-decoration: none;
}

.header-cta ul li {
  display: inline-block;
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid #e1e1e140;
}

.text-right {
  text-align: right;
}

.menu-area {
  padding: 0px 75px;
}

.text-right svg {
  color: #fff;
}

.main-logo img {
  width: 210px;
}

.institute_name h3 {
  margin-bottom: 0px;
  text-transform: uppercase;
  color: #00001a;
  font-weight: 700;
}

.institute_name p {
  margin-bottom: 0px;
  font-weight: 500;
}

.site-navbar {
  background-color: #fff;
  width: 100%;
  padding: 2px 0px;
}

.site-navbar .site-logo a {
  color: #6c5ebf;
  font-size: 20px;
  font-weight: 500;
  text-shadow: 1px 0px #000;
}

.site-navbar .site-navigation .site-menu {
  margin-bottom: 0;
}

.main-logo h1.site-logo {
  line-height: 20px;
}

.site-navbar .site-navigation .site-menu a {
  text-decoration: none !important;
  display: inline-block;
  /* font-weight: 500; */
}

.site-navigation span.icon-menu svg {
  color: #00001a;
  font-size: 30px;
}

.site-navbar .site-navigation .site-menu>li {
  display: inline-block;
  padding: 20px 9px;
}

.site-navbar .site-navigation .site-menu>li>a {
  color: #162542;
  font-size: 16px;
  text-decoration: none !important;
  font-weight: 600;
  font-family: "Jost", sans-serif;
}

.site-navbar .site-navigation .site-menu>li>a.active {
  color: #fe9d00;
}

.site-navbar .site-navigation .site-menu>li>a:hover {
  color: #fe9d00;
}

.site-navbar .site-navigation .site-menu>li:last-child {
  padding-right: 0;
}

.site-navbar .site-navigation .site-menu>li:last-child>a {
  padding-right: 0;
}

.site-navbar .site-navigation .site-menu .has-children {
  position: relative;
}

.site-navbar .site-navigation .site-menu .has-children>a {
  position: relative;
  padding-right: 20px;
}

.site-navbar .site-navigation .site-menu .has-children>a:before {
  position: absolute;
  content: "\f107";
  font-size: 16px;
  top: 53%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-weight: 900;
  font-family: "Font Awesome 6 Pro";
}

.site-navbar .site-navigation .site-menu .has-children .dropdown {
  visibility: hidden;
  opacity: 0;
  top: 100%;
  position: absolute;
  text-align: left;
  -webkit-box-shadow: 0 0px 10px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 0px 0;
  margin-top: 20px;
  margin-left: 0px;
  background: #fff;
  -webkit-transition: 0.2s 0s;
  -o-transition: 0.2s 0s;
  transition: 0.2s 0s;
  border-radius: 4px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top {
  position: absolute;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
  bottom: 100%;
  left: 20%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #fe9d00;
  border-width: 10px;
  margin-left: -10px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown a {
  font-size: 14px;
  text-transform: none;
  letter-spacing: normal;
  -webkit-transition: 0s all;
  -o-transition: 0s all;
  transition: 0s all;
  color: #343a40;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown a.active {
  color: #007bff;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown .active>a {
  color: #fff !important;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li {
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 200px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li:first-child>a {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li:last-child>a {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li>a {
  padding: 5px 20px;
  display: block;
  line-height: 25px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li>a:hover {
  background: #ebeef0;
  color: #212529;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children>a:before {
  content: "\e315";
  right: 20px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children>a.sub-menu-sub:before {
  content: "\f105";
  right: 20px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children>.dropdown,
.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children>ul {
  left: 100%;
  top: 0;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children:hover>a,
.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children:active>a,
.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children:focus>a {
  background: #ebeef0;
  color: #212529;
}

.site-navbar .site-navigation .site-menu .has-children:hover>a,
.site-navbar .site-navigation .site-menu .has-children:focus>a,
.site-navbar .site-navigation .site-menu .has-children:active>a {
  color: #fe9d00;
}

.site-navbar .site-navigation .site-menu .has-children:hover,
.site-navbar .site-navigation .site-menu .has-children:focus,
.site-navbar .site-navigation .site-menu .has-children:active {
  cursor: pointer;
}

.site-navbar .site-navigation .site-menu .has-children:hover>.dropdown,
.site-navbar .site-navigation .site-menu .has-children:focus>.dropdown,
.site-navbar .site-navigation .site-menu .has-children:active>.dropdown {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  margin-top: 0px;
  visibility: visible;
  opacity: 1;
  z-index: 2;
}

.site-mobile-menu {
  width: 300px;
  position: fixed;
  right: 0;
  z-index: 2000;
  padding-top: 20px;
  background: #00001a;
  height: calc(100vh);
  -webkit-transform: translateX(110%);
  -ms-transform: translateX(110%);
  transform: translateX(110%);
  -webkit-box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.offcanvas-menu .site-mobile-menu {
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.site-mobile-menu .site-mobile-menu-header {
  width: 100%;
  float: left;
  padding-left: 20px;
  padding-right: 20px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
  float: right;
  margin-top: 8px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
  font-size: 30px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 0px;
  line-height: 1;
  cursor: pointer;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  color: #fff;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span:hover {
  color: #dee2e6;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
  float: left;
  margin-top: 10px;
  margin-left: 0px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
  display: inline-block;
  text-transform: uppercase;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a img {
  max-width: 70px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
  text-decoration: none;
}

.site-mobile-menu .site-mobile-menu-body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding: 20px;
  height: calc(100vh - 52px);
  padding-bottom: 150px;
}

.site-mobile-menu .site-nav-wrap {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

.site-mobile-menu .site-nav-wrap a {
  padding: 7px 20px;
  display: block;
  position: relative;
  color: #fff;
}

.site-mobile-menu .site-nav-wrap a.active,
.site-mobile-menu .site-nav-wrap a:hover {
  color: #007bff;
}

.site-mobile-menu .site-nav-wrap li {
  position: relative;
  display: block;
}

.site-mobile-menu .site-nav-wrap li.active>a {
  color: #6c5ebf;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 20;
  width: 36px;
  height: 36px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  background-color: #fe9d00;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse:hover {
  background: #fe9d00;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse:before {
  font-size: 12px;
  z-index: 20;
  content: "\f107";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-180deg);
  transition: 0.3s all ease;
  font-weight: 900;
  font-family: "Font Awesome 6 Pro";
  color: #fff;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.site-mobile-menu .site-nav-wrap>li {
  display: block;
  position: relative;
  float: left;
  width: 100%;
}

.site-mobile-menu .site-nav-wrap>li>a {
  padding-left: 20px;
  font-size: 16px;
}

.site-mobile-menu .site-nav-wrap>li>ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.site-mobile-menu .site-nav-wrap>li>ul>li {
  display: block;
}

.site-mobile-menu .site-nav-wrap>li>ul>li>a {
  padding-left: 40px;
  font-size: 16px;
}

.site-mobile-menu .site-nav-wrap>li>ul>li>ul {
  padding: 0;
  margin: 0;
}

.site-mobile-menu .site-nav-wrap>li>ul>li>ul>li {
  display: block;
}

.site-mobile-menu .site-nav-wrap>li>ul>li>ul>li>a {
  font-size: 16px;
  padding-left: 60px;
}

.sticky-wrapper {
  z-index: 100;
  height: auto !important;
}

.sticky-wrapper+.site-blocks-cover {
  margin-top: 96px;
}

.sticky-wrapper .site-navbar {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.sticky-wrapper .site-navbar ul li.active a {
  color: #6c5ebf;
}

.sticky-wrapper.is-sticky .site-navbar {
  background-color: #fff;
  z-index: 999 !important;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.sticky-wrapper.is-sticky .site-navbar .site-navigation .site-menu>li {
  display: inline-block;
  padding: 10px 16px;
}

.sticky-wrapper.is-sticky .site-navbar .site-navigation .site-menu>li:last-child {
  padding-right: 0;
}

.sticky-wrapper.is-sticky .site-navbar .site-navigation .site-menu>li:last-child>a {
  padding-right: 0;
}

.sticky-wrapper.is-sticky .site-navbar .site-navigation .site-menu>li.has-children>a {
  padding-right: 20px;
}

.sticky-wrapper.is-sticky .site-navbar .site-navigation .site-menu>li>a {
  padding: 20px 0px;
  color: #222;
  font-size: 18px;
}

.sticky-wrapper.is-sticky .site-navbar .site-navigation .site-menu>li>a:hover {
  color: #ffb400 !important;
}

.sticky-wrapper.is-sticky .site-navbar .site-navigation .site-menu>li>a.active {
  color: #6c5ebf !important;
}

.sticky-wrapper.is-sticky .site-navbar .site-navigation .site-menu>li.active a {
  color: #6c5ebf;
}

.header-button a {
  padding: 23px 70px;
  border-radius: 30px 0;
  display: inline-block;
  transition: all 0.3s;
  cursor: pointer;
  outline: none !important;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  text-transform: uppercase;
  border: none;
  color: #fff;
  letter-spacing: 0.2px;
  background-color: #8d6a43;
  font-size: 13px;
}

.header-button a svg {
  font-size: 25px;
  margin-left: 10px;
}

.header-button a:hover {
  background-color: #000;
  color: #fff;
  border-radius: 0 20px 0 0;
}

/* header css end */

@media (max-width: 1440px) {
  .site-navbar .site-navigation .site-menu>li {
    padding: 20px 5px;
  }

  .call-box strong a {
    font-size: 16px;
  }

  .header-cta ul li {
    padding-right: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 1367px) {
  .site-navbar .site-navigation .site-menu .has-children>a {
    padding-right: 15px;
  }

  .site-navbar .site-navigation .site-menu>li {
    padding: 20px 3px;
  }
}

@media (max-width: 1280px) {
  .site-navbar .site-navigation .site-menu>li {
    padding: 20px 2px;
  }

  .site-navbar .site-navigation .site-menu>li>a {
    font-size: 15px;
  }

  .side-gap {
    padding: 0px 15px;
  }

  .header-cta .call-box .icon img {
    width: 30px;
  }
}

@media(max-width:1200px) {
  .top-btn a {
    background: #fe9d00;
    margin: 0px 3px 0px;
    padding: 7px 7px;
  }

  .call-box .text {
    margin-left: 5px;
  }
}

@media (max-width: 1024px) {
  .header-cta ul li {
    padding-right: 5px;
    margin-right: 5px;
  }

  .call-box strong a {
    font-size: 14px;
  }

  .top-btn a {
    font-size: 12px;
  }

  .institute_name h3 {
    font-size: 24px;
  }

  .institute_name p {
    font-size: 13px;
  }

  .site-navbar .site-navigation .site-menu>li {
    padding: 20px 2px;
  }

  .site-navbar .site-navigation .site-menu .has-children>a {
    padding-right: 14px;
  }

  .site-navbar .site-navigation .site-menu .has-children>a:before {
    font-size: 13px;
    top: 57%;
  }
}

@media (max-width: 992px) {
  .header-top::before {
    width: 26%;
  }
}

@media (max-width: 800px) {
  .header-top {
    display: none;
  }

  .top_header {
    display: block;
  }
}

@media(max-width:430px) {
  .main-logo img {
    width: 150px;
  }
}

@media (max-width: 360px) {
  .institute_name p {
    font-size: 12px;
  }
}